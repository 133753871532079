import { Link as GatsbyLink } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import logoImage from "../images/logo.svg"

import styled from "styled-components"

import AppBar from "@material-ui/core/AppBar"
import Hidden from "@material-ui/core/Hidden"
import Container from "@material-ui/core/Container"
import Toolbar from "@material-ui/core/Toolbar"
import Link from "@material-ui/core/Link"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"

const InlineList = styled(Box)`
  ul {
    display: flex;
    flex-direction: row;
  }
  li {
    padding: 0;
    margin-right: 65px;
    &:last-child {
      margin-right: 55px;
    }
  }
`
const LogoLink = styled(Link)`
  display: inline-block;
  padding: 10px 0 10px;
  img {
    max-width: 130px;
    vertical-align: top;
  }
`
const PageLink = styled(Link)`
  padding: 10px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #606060;
  white-space: nowrap;
`

const Header = ({ siteTitle }) => {
  const [drawer, setDrawer] = useState(false)

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawer(open)
  }
  const renderLinks = () => (
    <>
      <List disablePadding={true}>
        <ListItem>
          <PageLink component={GatsbyLink} to="/about" color="inherit">
            About
          </PageLink>
        </ListItem>
        <ListItem>
          <PageLink component={GatsbyLink} to="/in-development" color="inherit">
            In Development
          </PageLink>
        </ListItem>
        <ListItem>
          <PageLink component={GatsbyLink} to="/previous" color="inherit">
            Previous
          </PageLink>
        </ListItem>
        <ListItem>
          <PageLink component={GatsbyLink} to="/contact" color="inherit">
            Contact
          </PageLink>
        </ListItem>
      </List>
    </>
  )
  return (
    <>
      <AppBar position="static" color="inherit">
        <Container>
          <Toolbar disableGutters>
            <Box flexGrow={1}>
              <LogoLink
                component={GatsbyLink}
                to="/"
                aria-label={`${siteTitle}`}
              >
                <img src={logoImage} alt="" role="presentation" />
              </LogoLink>
            </Box>
            <Hidden smDown>
              <InlineList>{renderLinks()}</InlineList>
            </Hidden>
            <Hidden mdUp>
              <IconButton
                edge="start"
                onClick={toggleDrawer(true)}
                aria-label="menu"
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <SwipeableDrawer
        anchor={"left"}
        open={drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {renderLinks()}
      </SwipeableDrawer>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
